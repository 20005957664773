<!--
 * @Author: your name
 * @Date: 2020-11-24 12:17:18
 * @LastEditTime: 2021-05-10 09:54:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\adminInverter.vue
-->
<template>
    <div class="adminInverter-main-box">
        <div class="search-box">
            <div class="search-box-sub">
                <span class="lable-text">{{$t('project.data.region')}}</span>
                <el-select v-model="regionId" filterable :placeholder="$t('common.msg.select.please')" clearable @change="regionChange">
                    <el-option
                    v-for="item in regions"
                    :key="item.regionID"
                    :label="item.regionNameInternational"
                    :value="item.regionID">
                    </el-option>
                </el-select>
            </div>
            <div class="search-box-sub">
                <span class="lable-text">{{$t('common.msg.country')}}</span>
                <el-select v-model="countryID" filterable :placeholder="$t('common.msg.select.please')" clearable>
                    <el-option
                    v-for="item in countrys"
                    :key="item.countryID"
                    :label="item.countryNameInternational"
                    :value="item.countryID">
                    </el-option>
                </el-select>
            </div>
            <div class="search-box-sub">
                <span class="lable-text">{{$t('project.city')}}</span>
                <el-input v-model="cityNameNational" style="width:160px;" :placeholder="$t('common.info.pl-insert-content')"></el-input>
            </div>
            <div class="search-box-sub">
                <el-button type="primary" @click='query'>{{$t('common.button.query')}}</el-button>
            </div>
        </div>
        <div class="button-box">
            <el-button type="primary" class="marginR10" icon='el-icon-plus' @click="()=>{$router.push({path:'/addCity'})}">{{$t('system.city.add')}}</el-button>
            <el-popconfirm
                    :title="$t('common.button.del-bath-confirm')"
                    @confirm='systemCityDeleteBath()'
            >
                <el-button slot="reference" icon='el-icon-delete' class="marginR10" >{{$t('link.delete')}}</el-button>
            </el-popconfirm>
            <el-button type="primary" icon='el-icon-upload2' class="pull-right" @click="dialogVisible = true">{{$t('system.import')}}</el-button>
        </div>
        <baseTable v-loading="loading"  row-key='sid' :data="dataRows" border  stripe   :column='column' :select='true' @selection-change="handleSelectionChange($event)" >
            <template #cityNameInternational='{data}'>
                <a href="javascript:;" style="color: #4c98d9;" @click='pushEditUrl(data.cityID)'>{{data.cityNameInternational}}</a>
            </template>
            <template #option="{data}">
                <div class="option-box">
                    <el-popconfirm
                            :title="$t('common.button.del-bath-confirm')" @confirm='systemCityDelete(data.cityID)'>
                        <i class="el-icon-delete" slot="reference"></i>
                    </el-popconfirm>
                </div>
            </template>
        </baseTable>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <!--导入弹出框-->
        <el-dialog
                :title="$t('system.city.import')"
                :visible.sync="dialogVisible"
                width="30%"
                v-if='dialogVisible'
        >
            <el-button type="text" @click="download">
                {{$t('system.template.upload')}}
            </el-button>
            <base-file v-model="uploadFile" ></base-file>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('system.button.cancel')}}</el-button>
                <el-button type="primary" :loading="loadingBtn" @click="submitFile">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import baseTable from '../../components/common/baseTable'
import baseFile from '../../components/common/baseFileInputNew'
export default {
    components: {
        baseTable,
        baseFile
    },
    props: {

    },
    data() {
        return {
            uploadFile:'',
            dialogVisible: false,
            regions:[],
            countrys:[],
            manufacturerId:'',
            cityIDs:[],
            countryID: '',
            regionId:'',
            cityNameNational: '',
            name:'',
            selectDataList:[],
            loading:false,
            loadingBtn:false,
            dataRows:[],
            column:[{
                title: this.$t('project.data.region'),
                value: 'regionNameInternational',
            },{
                title: this.$t('common.msg.country'),
                value: 'countryNameInternational',
            },{
                title: this.$t('project.city'),
                value: 'cityNameInternational',
                slot: 'cityNameInternational'
            },{
                title: this.$t('project.data.lon'),
                value: 'longitude',
            },{
                title: this.$t('project.data.lat'),
                value: 'latitude',
            },{
                title: this.$t('common.msg.opeation'),
                value: 'option',
                slot: 'option'
            }],
            size:10,
            current:1,
            total:1
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.systemTCityGetPage();
        this.getAllRegion()
        this.getAllCountry()
    },
    methods: {

        /*导入模板下载*/
        download(){
            window.open(window.location.href.split('#')[0] + 'static/city.xls')
        },


        /*提交文件*/
        async submitFile() {
            if (!this.uploadFile) {
                this.$message({
                    message: this.$t('remind.upload-file-first'),
                    type: 'warning'
                });
                return
            }
            this.loadingBtn = true
            let res = await this.API.systemTCityImport({
                file: this.uploadFile
            })
            this.loadingBtn = false
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.dialogVisible = false
                this.uploadFile = ''
                this.systemTCityGetPage()
            }else {
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },


        /*区域选择值改变*/
        regionChange(){
            this.countryID = ''
            this.getAllCountry()
        },

        /*获取所有区域*/
        async getAllRegion(){
            let res = await this.API.systemRegionsGetAll({})
            this.regions = res;
        },

        /*获取所有国家*/
        async getAllCountry(){
            let res = await this.API.systemCountryGetAll({
                regionId: this.regionId
            })
            this.countrys = res
        },


        /*查询城市列表分页*/
        async systemTCityGetPage(){
            this.loading = true
            let res = await this.API.systemTCityGetPage({
                regionID: this.regionId,
                countryID: this.countryID,
                cityNameInternational: this.cityNameNational,
                current:this.current,
                size:this.size,
            })
            this.dataRows = res.rows
            this.total= parseInt(res.total)
            this.loading = false
        },

        /*删除*/
        async systemCityDelete(id){
            this.cityIDs = []
            this.cityIDs.push(id)
            this.systemCityDeleteBath()
        },

        /*批量删除*/
        async systemCityDeleteBath(){
            let res = await this.API.systemCityDeleteBath({
                cityIDs:this.cityIDs
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.systemTCityGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },


        /*跳转详情页面*/
        pushEditUrl(id){
            this.$router.push({path: '/addCity', query: {id:id}});
        },


        query(){
            this.current = 1
            this.systemTCityGetPage()
        },
        delete(id){
            console.log(id)
        },
        edit(id){
            console.log(id)
        },
        handleSelectionChange(val){
          this.cityIDs=val.map( item=>{
            return item.cityID
          })
        },
        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.systemTCityGetPage()
        },
        handleCurrentChange(val) {
            this.current = val
            this.systemTCityGetPage()
        },
    },
};
</script>

<style  lang="less" scoped>

</style>
